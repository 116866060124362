
import Url from "../../../urls.service";

export const getProducts = async (authToken) => {

    // const apiUrl = `${Url.product}?isRowProduct=true`;
    const apiUrl = `${Url.product}`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};

export const postProducts = async (authToken, payload) => {
    const apiUrl = Url.product;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken,
        },
        body: payload,
    });
    if (!response.ok) {
        throw new Error('Product Add Failed!');
    }
    return response.json();
};

export const getUnits = async (authToken) => {
    const apiUrl = Url.unit;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};


export const postRawInventory = async (authToken, payload) => {
    const apiUrl = Url.rowInventory;

    console.log("payload : ", JSON.stringify(payload));
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
        throw new Error('Product Add Failed!');
    }
    return response.json();
};

export const getRawInventory = async (authToken, payload) => {
    const apiUrl = `${Url.rowInventory}?product=${payload}`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + authToken,
        },
        // body: JSON.stringify(payload),
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};