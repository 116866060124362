
import Url from "../../../urls.service";

export const getSuppliers = async (authToken) => {
    const apiUrl = Url.supplier;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    if (!response.ok) {
        throw new Error('Get Supplier failed');
    }
    return response.json();
};

export const postSupplier = async (authToken, payload) => {
    const apiUrl = Url.supplier;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
        throw new Error(response.body);
    }
    return response.json();
};