import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OutForDeleveryForm from './OutForDeleveryForm'; // Assuming you have a ProductForm component
import './ProductList.css'; // Import your CSS file for styling
import { getOutForDelevery } from './OutForDeleveryAPI';

const OutForDeleveryList = () => {

    const [deleveryId, setDeleveryId] = useState(null);
    const [outForDeleveryList, setOutForDelevery] = useState([]);
    const [isAddingProduct, setIsAddingProduct] = useState(false);

    const handleBackToProductList = () => {
        setIsAddingProduct(false);
        setDeleveryId(null);
        getOutForDeleveryAPI();
    };

    const handleOrderClick = (order) => {
        setDeleveryId(order);
    };

    const handleAddProductClick = () => {
        setIsAddingProduct(true);
    };

    const authToken = useSelector((state) => state.auth.authToken);
    useEffect(() => {
        getOutForDeleveryAPI();
    }, []);

    const getOutForDeleveryAPI = async () => {
        const response = await getOutForDelevery(authToken);
        if (response.success) {
            setOutForDelevery(response.outForDelevery);
        }
    };

    return (<div>
        {isAddingProduct ? (<OutForDeleveryForm onClose={handleBackToProductList} />
        ) : deleveryId ? (
            <OutForDeleveryForm deleveryId={deleveryId} onClose={handleBackToProductList} />
        ) : (
            <div >
                <button className="add-product-button" onClick={handleAddProductClick}>
                    New Delevery
                </button>
                <div className="home-faq"></div>
                <table className="product-table">
                    {outForDeleveryList !== undefined ?
                        <tbody>
                            <React.Fragment >
                                <tr className={`category-row expanded`} >
                                    <th>Date</th>
                                    <th>Delivery code</th>
                                    <th>No of products</th>
                                    <th>Deleverd to</th>
                                </tr>
                                {outForDeleveryList.map((outForDelevery) => (
                                    <tr className="product-row" key={outForDelevery.id} onClick={() => handleOrderClick(outForDelevery)} >
                                        <td className="product-all-row">
                                            {outForDelevery.date}
                                        </td>
                                        <td className="product-all-row">
                                            {outForDelevery.deliveryId}
                                        </td>
                                        <td className="product-row"> {outForDelevery.prepairdInventory.length}</td>
                                        {outForDelevery.distributor ? <td className="product-row">{outForDelevery.distributor.name} (Distributor)</td> : null}
                                        {outForDelevery.salseman ? <td className="product-row">{outForDelevery.salseman.name} (Salseman)</td> : null}

                                    </tr>
                                ))}
                            </React.Fragment>
                        </tbody>
                        : null}
                </table>
            </div>
        )
        }
    </div>
    );
};

export default OutForDeleveryList;