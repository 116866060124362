import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddSupplier from './AddSupplier'; // Assuming you have a ProductForm component
import '../RowInventory/ProductList.css'; // Import your CSS file for styling
import { getSuppliers } from './SupplierAPI';
import ProductInventoryForm from '../RowInventory/ProductInventoryForm';

const SupplierList = () => {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isProductFormOpen, setProductFormOpen] = useState(false);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [supplierList, setProducts] = useState([]);
    const [isAddingProduct, setIsAddingProduct] = useState(false);


    const handleBackToProductList = () => {
        setIsAddingProduct(false);
        setSelectedProduct(null);
        getSuppliersAPI();
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleAddProductClick = () => {
        setIsAddingProduct(true);
    };

    const closeProductForm = () => {
        setProductFormOpen(false);
    };
    // const toggleCategory = (category) => {
    //     setExpandedCategories((prevCategories) => {
    //         if (prevCategories.includes(category)) {
    //             return prevCategories.filter((cat) => cat !== category);
    //         } else {
    //             return [...prevCategories, category];
    //         }
    //     });
    // };

    const authToken = useSelector((state) => state.auth.authToken);
    useEffect(() => {
        getSuppliersAPI();
    }, []);

    const getSuppliersAPI = async () => {
        const response = await getSuppliers(authToken);
        if (response.success) {
            setProducts(response.suppliers);
        }
    };
    return (<div>
        {isAddingProduct ? (<AddSupplier onClose={handleBackToProductList} />
        ) : selectedProduct ? (
            <ProductInventoryForm product={selectedProduct} onClose={handleBackToProductList} />
            // <ProductDetail product={selectedProduct} onClose={handleBackToProductList} />
        ) : (
            <div >

                <button className="add-product-button" onClick={handleAddProductClick}>
                    Add Supplier
                </button>
                <div className="home-faq"></div>
                <table className="product-table">
                    {supplierList !== undefined ?
                        <tbody>
                            <tr className={`category-row expanded`}>
                                <th>Name</th>
                                <th>Organization</th>
                                <th>Contact No.</th>
                                <th>Alternate Contact No</th>
                                <th>Deals in</th>
                            </tr>
                            {
                                supplierList.map((product) => (
                                    <tr className="product-row" key={product.id} >

                                        <td>{product.supplierSrNo}</td>
                                        <td>{product.name}</td>
                                        <td>{product.organization}</td>
                                        <td>{product.contactNo}</td>
                                        <td>{product.alternateContact}</td>
                                        <td>{product.description}</td>
                                    </tr>
                                ))}
                        </tbody>
                        : null}
                </table>
            </div>
        )
        }
    </div>
    );
};

export default SupplierList;