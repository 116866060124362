import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProductList from './ProductList/ProductList';
import RowInventoryProductList from './RowInventory/RowInventoryProductList';
import PrepairedProductInventoryList from './PrepairedProductsInventory/PrepairedProductInventoryList';
import './Dashboard.css';
import logo from '../../images/LogoMBN_white.png';
import { getProductCategory } from './Login/LoginAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setProductCategory } from '../../redux/reducers/authActions';
import productIcon from '../../images/DrowerIcon/Cart.png';
import inventoryIcon from '../../images/DrowerIcon/Inventory.png';
import packedIcon from '../../images/DrowerIcon/PackedIcon.png';
import delevery from '../../images/DrowerIcon/delevery.png';
import supplierIcon from '../../images/DrowerIcon/Supplier.png';
import SupplierList from '../Inventory/Supplier/SupplierList';
import OutForDeleveryList from '../Inventory/OutForDelevery/OutForDeleveryList';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const Dashboard = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(true);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(!openDrawer);
    };

    const handleListItemClick = (item) => {
        setSelectedItem(item);
    };

    useEffect(() => {
        fetchProductCategory();
    }, []);

    const authToken = useSelector((state) => state.auth.authToken)
    const fetchProductCategory = async () => {
        const responce = await getProductCategory(authToken);
        if (responce.success) {
            const categories = responce.categories
            dispatch(setProductCategory(categories));
        }
    }

    const renderRightPanelContent = () => {
        // Add logic to render content based on the selected item
        switch (selectedItem) {
            case 'Product_List':
                return <ProductList />;// return <Typography variant="h5">Content for Menu Item 1</Typography>;
            case 'SupplierList':
                return <SupplierList />;
            case 'Update_Row_Inventory':
                return <RowInventoryProductList product="" />;
            case 'Prepaired_Inventory':
                return <PrepairedProductInventoryList product="" />;
            case 'Out_for_delevery':
                return <OutForDeleveryList product="" />;

            default:
                return <div><p>Work on progress!</p></div>;
        }
    };

    return (
        <div className={classes.root} >
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: '#3cb40c' }} >
                <Toolbar style={{ backgroundColor: '#2596be' }} >
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>

                        <img className="home-logo" src={logo} alt="My Local" />
                    </Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <IconButton color="inherit">
                        <AccountCircleIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {openDrawer ?
                <Drawer

                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={openDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar style={{ backgroundColor: '#2596be' }} >
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />

                        </IconButton>
                        <img className="home-logo" src={logo} alt="My Local" />
                    </Toolbar>

                    <List style={{ backgroundColor: '#f0f0f0' }}>
                        {/* <ListItemText primary="Product List" /> */}
                        <ListItem button onClick={() => handleListItemClick('Product_List')}>
                            <ListItemIcon>
                                <img
                                    src={productIcon}
                                    className="product-list-icon"
                                    alt="My Local"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Product List" />
                        </ListItem>
                        <ListItem button onClick={() => handleListItemClick('SupplierList')}>
                            <ListItemIcon>
                                <img
                                    src={supplierIcon} className="product-list-icon" alt="My Local"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Supplier List" />
                        </ListItem>
                        <ListItem button onClick={() => handleListItemClick('Update_Row_Inventory')}>
                            <ListItemIcon>
                                <img
                                    src={inventoryIcon} className="product-list-icon" alt="My Local"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Raw Inventory" />
                        </ListItem>
                        <ListItem button onClick={() => handleListItemClick('Prepaired_Inventory')}>
                            <ListItemIcon>
                                <img
                                    src={packedIcon} className="product-list-icon" alt="My Local"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Prepaired Inventory" />
                        </ListItem>
                        <ListItem button onClick={() => handleListItemClick('Out_for_delevery')}>
                            <ListItemIcon>
                                <img
                                    src={delevery} className="product-list-icon" alt="My Local"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Out for delevery" />
                        </ListItem>
                    </List>

                </Drawer> : null}
            <main className={classes.content}>
                <Toolbar />
                <Typography paragraph>
                    {renderRightPanelContent()}
                </Typography>
            </main>
        </div>
    );
};

export default Dashboard;