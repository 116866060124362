import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import './style.css'
import Home from './views/mbn-web/home'
import AboutUs from './views/mbn-web/about-us'
import ContactUs from './views/mbn-web/contact-us'
import Team from './views/mbn-web/team'
import ProductList from './views/Inventory/ProductList/ProductList';
import PrepairedProductInventoryList from './views/Inventory/PrepairedProductsInventory/PrepairedProductInventoryList';
import BillGenerationForm from './views/mbn-web/extra';
import Login from './views/Inventory/Login/Login';
import store from './redux/store';
import Dashboard from './views/Inventory/Dashboard';
import AddProductForm from './views/Inventory/ProductList/AddProductForm'
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = store.getState().auth.authToken;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated !== null ? <ThemeProvider theme={theme}> <Component {...props} /> </ThemeProvider> : <Redirect to="/login" />
      }
    />
  );
};

const App = () => {

  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Home} exact path="/qrscan" />
        <Route component={AboutUs} path="/about" />
        <Route component={ContactUs} path="/contactUs" />
        <Route component={Team} path="/Team" />
        <PrivateRoute component={ProductList} path="/Inventory" />
        <PrivateRoute component={BillGenerationForm} path="/Extra" />
        <PrivateRoute component={PrepairedProductInventoryList} path="/PrepairedProductInventoryList" />
        <PrivateRoute component={Dashboard} path="/Dashboard" />
        <PrivateRoute component={AddProductForm} path="/addp" />
        <Route component={Login} path="/login" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

export default App;