import React, { useEffect, useState } from 'react';
import './ProductList.css';
import { useSelector } from 'react-redux';
import { getProducts } from './PrepairedProductAPI';
import { getPacketSize } from './PrepairedProductAPI';
import { postPrepairedInventory } from './PrepairedProductAPI';
import { getPrepairedInventory } from './PrepairedProductAPI';

const PrepairedProductInventoryForm = ({ product, onClose }) => {
    const [selectedProduct, setSelectedProduct] = useState(product);
    const [packetSizeList, setPacketSize] = useState([]);
    const [productsList, setProducts] = useState([]);
    const [formData, setFormData] = useState({
        nameEng: selectedProduct.nameEng,
        productImage: selectedProduct.image,
        noOfBundels: '',
        noOfPaketsInBundels: '',
        packetSize: '',
    });
    const authToken = useSelector((state) => state.auth.authToken);
    const [inventoryHistory, setInventoryHistory] = useState([]);

    useEffect(() => {
        setSelectedProduct(product);
        productListAPI();
        getPacketSizeList();
        getPrepairedInventoryList(product);
    }, [product]);

    const productListAPI = async () => {
        const response = await getProducts(authToken);
        if (response.success) {
            const products = [...response.products].sort((a, b) => a.nameEng > b.nameEng ? 1 : -1,);
            setProducts(products);
        }
    };

    const getPacketSizeList = async () => {
        const response = await getPacketSize(authToken);
        if (response.success) {
            const packetSize = [...response.packetSize];
            setPacketSize(packetSize);
        }
    };

    const getPrepairedInventoryList = async (curretProduct) => {
        const response = await getPrepairedInventory(authToken, curretProduct._id);
        if (response.success) {
            const prepairdProductInventory = [...response.prepairdProductInventory];
            setInventoryHistory(prepairdProductInventory);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddInventory = async () => {
        var selectedPacketSize = packetSizeList.filter((packet) => packet.value === parseInt(formData.packetSize))[0];
        const payload = {
            noOfBundels: formData.noOfBundels,
            noOfPaketsInBundels: formData.noOfPaketsInBundels,
            packetSize: selectedPacketSize._id,
            product: selectedProduct._id,
        };

        const response = await postPrepairedInventory(authToken, payload);
        if (response.success) {
            alert('Inventory added successfully!');
            getPrepairedInventoryList(product);
            resetForm();
        } else {
            resetForm();
        }
    };

    const handleProductChange = (e) => {
        const { name, value } = e.target;
        var selectedProdutFromDD = productsList.filter((packet) => packet.nameEng === value)[0];
        if (selectedProdutFromDD) {
            setSelectedProduct(selectedProdutFromDD);
            getPrepairedInventoryList(selectedProdutFromDD);
        }
    };
    const resetForm = () => {
        setFormData({
            nameEng: selectedProduct.nameEng,
            productImage: selectedProduct.image,
            noOfBundels: '',
            noOfPaketsInBundels: '',
            packetSize: '',
        });
    }

    return (
        <div>
            <button onClick={onClose}>Back to Product List</button>
            <form>
                <div>
                    <div className="product-detail">
                        <img className="product-image" src={process.env.REACT_APP_API_URL + selectedProduct.image} alt={selectedProduct.name} />
                        <h3>{selectedProduct.nameEng + "(" + selectedProduct.nameHindi + ")"}   : {product.totalQty}</h3>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <select style={{ width: '50%', height: '30px' }} name="productName" value={formData.productsList} onChange={handleProductChange}>
                        <option value="" selected disabled hidden>Select Product</option>
                        {productsList.map((productsList) => (<option value={productsList.nameEng}>{productsList.nameEng + " (" + productsList.nameHindi + ")"}</option>))}
                    </select>
                </div>
                <br></br>
                <div>
                    <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                        <label>
                            Packet Size:
                            <select style={{ width: '100%', height: '30px' }} name="packetSize" value={formData.packetSize} onChange={handleChange}>
                                <option value="" selected disabled hidden>Select Size</option>
                                {packetSizeList.map((packetSize) => (<option value={packetSize.value}>{packetSize.value} {packetSize.unit.shortName}</option>))}
                            </select>
                        </label>
                    </div>
                </div>
                <br></br>
                <label>
                    No. Of Bundels:
                    <input
                        type="number"
                        name="noOfBundels"
                        value={formData.noOfBundels}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    No. Of Packets per Bundels:
                    <input
                        type="number"
                        name="noOfPaketsInBundels"
                        value={formData.noOfPaketsInBundels}
                        onChange={handleChange}
                    />
                </label>
                <br></br>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button type="button" onClick={handleAddInventory}>
                        Save
                    </button>
                </div>
            </form>
            <table>
                <thead>
                    <tr >
                        <th>Date</th>
                        <th>Product Name</th>
                        <th>Bundels</th>
                        <th>Pakets per Bundels </th>
                        <th>Size</th>
                        <th>Balance Packets</th>
                        <th>Balance Bundel</th>
                        <th>Opration</th>

                    </tr>
                </thead>
                <tbody>


                    {inventoryHistory.map((entry, index) => (
                        <tr key={index} style={entry.opration === 'dr' ? { color: '#854545' } : { color: '#508545' }} >
                            <td>{entry.date}</td>
                            <td>{entry.product.nameEng + "(" + entry.product.nameHindi + ")"}</td>
                            <td>{entry.noOfBundels}</td>
                            <td>{entry.noOfPaketsInBundels}</td>
                            <td>{entry.packetSize.value + entry.packetSize.unit.shortName}</td>
                            <td>{entry.balancePackets}</td>
                            <td>{entry.balanceBundel}</td>
                            <td>{entry.opration}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
};

export default PrepairedProductInventoryForm;