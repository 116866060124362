import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddProductForm from './AddProductForm'; // Assuming you have a ProductForm component
import '../RowInventory/ProductList.css'; // Import your CSS file for styling
import { getProducts } from './productAPI';
import ProductInventoryForm from '../RowInventory/ProductInventoryForm';

const ProductList = () => {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isProductFormOpen, setProductFormOpen] = useState(false);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [productsList, setProducts] = useState([]);
    const [isAddingProduct, setIsAddingProduct] = useState(false);


    const handleBackToProductList = () => {
        setIsAddingProduct(false);
        setSelectedProduct(null);
        productListAPI();
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleAddProductClick = () => {
        setIsAddingProduct(true);
    };

    const closeProductForm = () => {
        setProductFormOpen(false);
    };
    const toggleCategory = (category) => {
        setExpandedCategories((prevCategories) => {
            if (prevCategories.includes(category)) {
                return prevCategories.filter((cat) => cat !== category);
            } else {
                return [...prevCategories, category];
            }
        });
    };

    const authToken = useSelector((state) => state.auth.authToken);
    useEffect(() => {

        productListAPI();
    }, []);

    const productListAPI = async () => {
        const response = await getProducts(authToken);
        if (response.success) {
            setProducts(response.products);
        }
    };
    return (<div>
        {isAddingProduct ? (<AddProductForm onClose={handleBackToProductList} />
        ) : selectedProduct ? (
            <ProductInventoryForm product={selectedProduct} onClose={handleBackToProductList} />
            // <ProductDetail product={selectedProduct} onClose={handleBackToProductList} />
        ) : (
            <div >

                <button className="add-product-button" onClick={handleAddProductClick}>
                    Add Product
                </button>
                <div className="home-faq"></div>
                <table className="product-table">
                    {productsList !== undefined ?
                        <tbody>
                            {Array.from(new Set(productsList.map((product) => product.category.name))).map((category) => (
                                <React.Fragment key={category}>
                                    <tr
                                        className={`category-row ${expandedCategories.includes(category) ? 'expanded' : ''
                                            }`}
                                    >
                                        <th></th>
                                        <th></th>
                                        <th onClick={() => toggleCategory(category)}>
                                            {category}
                                        </th>

                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    {
                                        productsList
                                            .filter((product) => product.category.name === category)
                                            .map((product) => (
                                                <tr className="product-row" key={product.id} >
                                                    <td className="product-row">ID: {product.productSrNo}</td>
                                                    <td className="product-image-row" >
                                                        <img
                                                            src={process.env.REACT_APP_API_URL + product.image}
                                                            className="product-thumb-image"
                                                            alt="Product Image"
                                                        />
                                                    </td>
                                                    <td className="product-image-blank" ></td>
                                                    <td className="product-all-row">
                                                        {product.nameEng}({product.nameHindi})
                                                    </td>
                                                    <td className="product-row">code: {product.code}</td>

                                                    <td>{product.description}</td>
                                                    {/* <td>
                                                        <button onClick={() => handleProductClick(product)}>View Details</button>
                                                    </td> */}
                                                </tr>
                                            ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                        : null}
                </table>

                {/* {selectedProduct && <ProductDetail product={selectedProduct} />}
            {isProductFormOpen && <ProductForm onClose={closeProductForm} />} */}
            </div>
        )
        }
    </div>
    );
};

export default ProductList;

// alt = { product.title }