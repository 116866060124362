import React, { useState } from 'react';

const products = [
  { id: 1, name: 'Product A', price: 10 },
  { id: 2, name: 'Product B', price: 20 },
  { id: 3, name: 'Product C', price: 30 },
];

const BillGenerationForm = () => {
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const handleProductChange = (e) => {
    setSelectedProduct(e.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value, 10));
  };

  const handleAddProduct = () => {
    const product = products.find((p) => p.id === parseInt(selectedProduct, 10));

    if (product) {
      const existingProduct = selectedProducts.find((p) => p.id === product.id);

      if (existingProduct) {
        // If the product is already in the list, update the quantity
        const updatedProducts = selectedProducts.map((p) =>
          p.id === existingProduct.id ? { ...p, quantity: p.quantity + quantity } : p
        );

        setSelectedProducts(updatedProducts);
      } else {
        // If the product is not in the list, add it with the selected quantity
        setSelectedProducts([...selectedProducts, { ...product, quantity }]);
      }

      // Reset the selected product and quantity
      setSelectedProduct('');
      setQuantity(1);
    }
  };

  const handleRemoveProduct = (productId) => {
    const updatedProducts = selectedProducts.filter((p) => p.id !== productId);
    setSelectedProducts(updatedProducts);
  };

  const calculateTotalAmount = () => {
    return selectedProducts.reduce((total, product) => total + product.price * product.quantity, 0);
  };

  return (
    <div>
      <h2>Bill Generation</h2>
      <form>
        <label>
          Select Product:
          <select value={selectedProduct} onChange={handleProductChange}>
            <option value="">Select a product</option>
            {products.map((product) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Quantity:
          <input type="number" value={quantity} onChange={handleQuantityChange} />
        </label>
        <button type="button" onClick={handleAddProduct}>
          Add Product
        </button>

        <h3>Selected Products</h3>
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {selectedProducts.map((product) => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>{product.quantity}</td>
                <td>${product.price}</td>
                <td>${product.price * product.quantity}</td>
                <td>
                  <button type="button" onClick={() => handleRemoveProduct(product.id)}>
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h3>Total Amount: ${calculateTotalAmount()}</h3>

        <button type="submit">Generate Bill</button>
      </form>
    </div>
  );
};

export default BillGenerationForm;
