// src/components/ContactUsForm.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import './home.css'
import './about-us.css'
import WhatsApp from '../../images/whatsApp.png';
import Header from './header';
import Footer from './footer';
import WhatsAppFloatingButton from './WhatsAppFloatingButton';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic form validation
    let errors = {};
    let hasErrors = false;

    Object.entries(formData).forEach(([key, value]) => {
      if (!value.trim()) {
        errors[key] = 'This field is required';
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setFormErrors(errors);
      return;
    }

    // TODO: Handle form submission logic (e.g., send data to server)
    console.log('Form submitted:', formData);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>MBN</title>
        <meta property="og:title" content="MBN" />
      </Helmet>
      <div className="home-Top-Background">
        <Header />
      </div>
      <div className="contact-details">
        <h2>Contact Details</h2>
        <p>
          <strong>Address:</strong> Near I.I.M Locknow 226013
        </p>

        <p>
          <strong>Email:</strong> info@mbnproducts.com
        </p>
        <p>
          <strong>Contact Number:</strong> +91 9044554119, +91 9044554116
        </p>
        <p>

          <a
            href="https://wa.me/9044554119"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="whatsApp-logo" src={WhatsApp} alt="Whats App logo" ></img>
          </a>
        </p>
      </div>

      <form className="contact-form" onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <span className="error-message">{formErrors.name}</span>
        </label>

        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <span className="error-message">{formErrors.email}</span>
        </label>

        <label>
          Subject:
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
          />
          <span className="error-message">{formErrors.subject}</span>
        </label>

        <label>
          Message:
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
          <span className="error-message">{formErrors.message}</span>
        </label>

        <button type="submit">Submit</button>
      </form>

      <div className="home-faq"></div>
      <Footer />
      <WhatsAppFloatingButton />
    </div>
  );
};

export default ContactUs;
