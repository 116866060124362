import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getProducts } from './PrepairedProductAPI';
import ProductInventoryForm from './PrepairedProductInventoryForm';
import './ProductList.css';

const PrepairedProductInventoryList = () => {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isProductFormOpen, setProductFormOpen] = useState(false);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [productsList, setProducts] = useState([]);
    const [isAddingProduct, setIsAddingProduct] = useState(false);

    const handleBackToProductList = () => {
        setIsAddingProduct(false);
        setSelectedProduct(null);
        productListAPI();
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleAddProductClick = () => {
        setIsAddingProduct(true);
    };

    const closeProductForm = () => {
        setProductFormOpen(false);
    };
    const toggleCategory = (category) => {
        setExpandedCategories((prevCategories) => {
            if (prevCategories.includes(category)) {
                return prevCategories.filter((cat) => cat !== category);
            } else {
                return [...prevCategories, category];
            }
        });
    };

    const authToken = useSelector((state) => state.auth.authToken);
    useEffect(() => {
        productListAPI();
    }, []);

    const productListAPI = async () => {
        const response = await getProducts(authToken);
        if (response.success) {
            setProducts(response.products);
        }
    };

    return (<div>
        {
            selectedProduct ? (
                <ProductInventoryForm product={selectedProduct} onClose={handleBackToProductList} />
            ) : (
                <div >
                    <table className="product-table">
                        {productsList !== undefined ?
                            <tbody>
                                {Array.from(new Set(productsList.map((product) => product.category.name))).map((category) => (
                                    <React.Fragment key={category}>

                                        <tr
                                            className={`category-row ${expandedCategories.includes(category) ? 'expanded' : ''
                                                }`}
                                        >
                                            <th></th>
                                            <th></th>
                                            <th onClick={() => toggleCategory(category)}>
                                                {category}
                                            </th>

                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>

                                        {
                                            productsList
                                                .filter((product) => product.category.name === category)
                                                .map((product) => (
                                                    <tr className="product-row" key={product.id} onClick={() => handleProductClick(product)}>
                                                        <td className="product-image-row" >
                                                            <img
                                                                src={process.env.REACT_APP_API_URL + product.image}
                                                                className="product-image"
                                                                alt="Product Image"
                                                            />
                                                        </td>
                                                        <td className="product-image-blank" ></td>
                                                        <td className="product-all-row">
                                                            {product.nameEng}({product.nameHindi})
                                                        </td>
                                                        <td className="product-row">code: {product.code}</td>
                                                        <td>{product.qty} {product.unit}</td>
                                                    </tr>
                                                ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                            : null}
                    </table>
                </div>
            )
        }
    </div>
    );
};

export default PrepairedProductInventoryList;