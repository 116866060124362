import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../RowInventory/ProductList.css';
import { postSupplier } from './SupplierAPI';
const AddSupplier = ({ onClose }) => {

    const [formData, setFormData] = useState({
        name: '',
        organization: '',
        contactNo: '',
        alternateContact: '',
        description: '',
        active: true,
    });

    const token = useSelector((state) => state.auth.authToken);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const removeAlternateContact = () => {
        setFormData(current => {
            // 👇️ remove the salary key from an object
            const { alternateContact, ...rest } = current;

            return rest;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var responce;
        if (formData.alternateContact.length !== 0 && formData.alternateContact !== 0 && formData.alternateContact !== "0") {
            responce = await postSupplier(token, formData);
        } else {
            responce = await postSupplier(token, {
                name: formData.name,
                organization: formData.organization,
                contactNo: formData.contactNo,
                description: formData.description,
                active: true,
            });
        }

        if (responce.success) {
            alert('Supplier added successfully!');
            onClose();
        }
    };

    return (
        <div>
            <h3>Add Supplier</h3>
            <button type="button" onClick={onClose}>
                Back
            </button>
            <form onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                </label>
                <label>
                    Organization:
                    <input type="text" name="organization" value={formData.organization} onChange={handleChange} />
                </label>
                <label>
                    Contact No:
                    <input type="number" name="contactNo" value={formData.contactNo} onChange={handleChange} />
                </label>
                <label>
                    Alternate Contact No:
                    <input type="number" name="alternateContact" value={formData.alternateContact} onChange={handleChange} />
                </label>
                <label>
                    Deals in:
                    <textarea name="description" value={formData.description} onChange={handleChange} />
                </label>
                <button type="submit">Save</button>


            </form>
        </div >
    );
};

export default AddSupplier;