import React from 'react'
import { Helmet } from 'react-helmet'
import './home.css'
import './about-us.css'
import Header from './header';
import Footer from './footer';
import WhatsAppFloatingButton from './WhatsAppFloatingButton';

const Team = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>MBN</title>
        <meta property="og:title" content="MBN" />
      </Helmet>
      <div className="home-Top-Background">
        <Header />
      </div>

      <div className="home-details">
        <div className="home-details1">
          <div className="home-container2">

            <h2 className="home-details-heading heading2">
              Nishant
            </h2>
            <span className="home-details-sub-heading">
              We are passionate about providing high-quality spices sourced from
              around the world. With our product listing, you can
              easily browse and purchase your favorite spices. Contact us today
              to learn more about our products and services.
            </span>
          </div>
          <img
            alt="image"
            src=""
            className="home-details-image"
          />
        </div>
      </div>
      <div className="home-faq"></div>
      <Footer />
      <WhatsAppFloatingButton />
    </div >
  )
}

export default Team
