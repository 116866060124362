import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import masterDataReducer from './reducers/masterDataReducer';
import publicKeyReducer from './reducers/publicKeyReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    masterData: masterDataReducer,
    key: publicKeyReducer,
});

export default rootReducer;