import React, { useState, useEffect } from 'react';
import { loginUser, generateKeyPair } from './LoginAPI';
import { useDispatch } from 'react-redux';
import './Login.css';
import { setAuthToken, setPublicKey } from '../../../redux/reducers/authActions';
import { useHistory } from 'react-router-dom';
import forge from 'node-forge';

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [publicKey, setPublicKeyValue] = useState('');
    const [loginStatus, setLoginStatus] = useState(null);

    const getPrivateKey = async () => {
        try {
            const response = await generateKeyPair();
            if (response.success) {
                const publicKey = response.publicKey;
                // console.log(publicKey);
                setPublicKeyValue(response.publicKey)
                dispatch(setPublicKey(response.publicKey));
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    useEffect(() => {
        getPrivateKey();
    }, []);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const encryptWithPublicKey = async (plaintext) => {
        // const buffer = Buffer.from(plaintext, 'utf-8');
        // const encrypted = await window.crypto.publicEncrypt(publicKey, plaintext);
        // console.log(encrypted.toString('base64'));
        // return encrypted.toString('base64');
        // const encrypted = CryptoJS.AES.encrypt(plaintext, publicKey).toString();
        // return encrypted;

        // const publicKeyPem = forge.pki.publicKeyFromPem(publicKey);
        // const encrypted = publicKeyPem.encrypt(plaintext, 'RSA-OAEP');
        // return encrypted.toString('base64');


        // const encryptor = new JSEncrypt();
        // encryptor.setPublicKey(publicKey);
        // const encrypted1 = encryptor.encrypt(plaintext);

        // const buffer1 = Buffer.from("rr", 'utf-8');
        // const encrypted1 = crypto.publicEncrypt(publicKey, buffer1);

        const publicKey1 = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtuSCFASujMUZH2bUDW5x
ei8nD6l60s+nuQFi3CThjMDQzitwaCb+y46fspYr/FttdxV0N/JnGr7QfsTtUgJa
e/mu77z0y+wwv3NrEpzoO9JpxW9m9MpINV1g1ebaL+MX1OSuosvGKhqGE5yTkw5j
BkzGWfWHNMGqaVKYoBAibrGB2jeZJ1T+v96Ahhsg+mBZ/TRlckcpkOp0Wss9XJcF
ronMZD2lTPITpAZuDSt+JwawPawRyMYGLYp/hsZBJYnp1uiKMjGvfSmNiMyCz/pR
Vj0GEfb04jfKDxnve1ARgDE6U7v8FPrz3vCE+OqpfQsSS2g/w9FWiNq8UV5S3kB0
wwIDAQAB
-----END PUBLIC KEY-----`;

        console.log("plaintext: ", plaintext);
        const publicKeyForge = forge.pki.publicKeyFromPem(publicKey1);
        const buffer1 = forge.util.encodeUtf8(plaintext);
        const encrypted1 = publicKeyForge.encrypt(buffer1, "NONE");
        return forge.util.encode64(encrypted1);

    };

    const handleLogin = async () => {
        try {
            //  const encryptedPassword = await encryptWithPublicKey(password);
            const response = await loginUser(username, password);
            if (response.success) {
                setLoginStatus('success');
                const token = response.token;
                dispatch(setAuthToken(token));
                history.push('/Dashboard');
            } else {
                setLoginStatus('failure');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setLoginStatus('failure');
        }
    };
    return (
        <div className="login-fullscreen">
            <div className="login-center">
                <div className="login-container">
                    <div className="login-form">
                        <h2>Login</h2>
                        <label>
                            User Name:
                            <input type="text" value={username} onChange={handleUsernameChange} />
                        </label>
                        <label>
                            Password:
                            <input type="password" value={password} onChange={handlePasswordChange} />
                        </label>
                        <button onClick={handleLogin}>Login</button>
                        {loginStatus === 'success' && <p className="success-message">Login successful!</p>}
                        {loginStatus === 'failure' && <p className="failure-message">Login failed. Please try again.</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;

// Encrypt.js

// import React from 'react';
// import forge from 'node-forge';

// class Login extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             plaintext: '',
//             encryptedText: ''
//         };
//     }

//     handleChange = (event) => {
//         this.setState({ plaintext: event.target.value });
//     }

//     encryptData = () => {
//         const publicKeyPem = `-----BEGIN PUBLIC KEY-----
// MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvbvrJJYdWAMZjmcG+XRC
// gguTzqHDT6XuUjABKlEP+5a+oF7hD/WZHHeSfk5Qho4UqTMKb9GrNJC0ItJ5976v
// Jc+5Ctmv4uu/qX1JK6Q0B1YUU8iUwiYe+lcb/c6s/WuE+5pTt7nMjchCg3K2/Otn
// L+6YiWHnoEe4UeiRLeDaBFzKAfOP5A6Lz5v8idMyarJ1g3rEKaMe79nWsChPsZmv
// /fjwE+1UrZhWaPQcaMf0kU1jan+GHXqJNvPBHkgyvpZJ/dJjhL6Vah9lutg+jxEL
// /0p+eS1fA0EL5fJSb2osUjstTaMssTaFI6JeaGZz6v0kbE6vkWmsNpMP01kTHDzi
// CwIDAQAB
// -----END PUBLIC KEY-----`;

//         const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
//         const encrypted = publicKey.encrypt(this.state.plaintext, 'RSA-OAEP');
//         this.setState({ encryptedText: encrypted.toString('base64') });
//     }

//     render() {
//         return (
//             <div>
//                 <textarea value={this.state.plaintext} onChange={this.handleChange} />
//                 <button onClick={this.encryptData}>Encrypt</button>
//                 <div>{this.state.encryptedText}</div>
//             </div>
//         );
//     }
// }

// export default Login;


// import React, { useEffect, useState } from 'react';
// import JSEncrypt from 'jsencrypt';

// const MyComponent = () => {
//     const [encryptedData, setEncryptedData] = useState(null);
//     const publicKey = `-----BEGIN PUBLIC KEY-----
// MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy+Bl34Jgdn26IGbJSti1
// Wni7ZoiMNLTXI6shwcBPBkqYg0w3oEVKrrD1UKHNCyZNffxfsxIsYElwuGpg9Mrf
// HiwizkRwGcn07ibV5CguZZYEEkN7CDHSB9WkoqfY/Op13h1iHhs3hXEyrzmfAOe4
// 71eZnuzK+f7dJj0wjYObzRqzWGCD3qlDun4txRk36Xok7F93HXRSRS9t5ATT44BZ
// KqZh2lnEYoHHNE2Te+2ni5c4b3r8A4Z/knxrlUV9x9/AZtGvU1FuUgR09z7yxw2c
// NSAAvrwW95t7h28gsWudS4nrfzyDFH+0nJXVzrr9GE6eXKxhJKNlza3L+0TD2AqK
// 3QIDAQAB
// -----END PUBLIC KEY-----`; // Replace 'your_public_key_here' with your actual public key

//     useEffect(() => {
//         const encryptor = new JSEncrypt();
//         encryptor.setPublicKey(publicKey);
//         const encrypted1 = encryptor.encrypt('rr');
//         setEncryptedData(encrypted1);
//     }, [publicKey]);

//     return (
//         <div>
//             <h1>Encrypted Data:</h1>
//             <p>{encryptedData}</p>
//         </div>
//     );
// };

// export default MyComponent;
