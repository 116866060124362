import React, { useEffect, useState } from 'react';
import './ProductList.css';
import { useSelector } from 'react-redux';
import { getProducts } from './OutForDeleveryAPI';
import { getPacketSize } from './OutForDeleveryAPI';
import { postOutForDelevery } from './OutForDeleveryAPI';
import { getOutForDeleveryForID } from './OutForDeleveryAPI';
import { getSalseManListAPI } from './OutForDeleveryAPI';
import { getDistributorListAPI } from './OutForDeleveryAPI';
import { deleteOutForDeleveryProduct } from './OutForDeleveryAPI';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const OutForDeleveryForm = ({ deleveryId, onClose }) => {
    const [selectedProduct, setSelectedProduct] = useState();
    const [packetSizeList, setPacketSize] = useState([]);
    const [salseManList, setSalseManList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [productsList, setProducts] = useState([]);
    const authToken = useSelector((state) => state.auth.authToken);
    const [outForDeleveryHistory, setoutForDeleveryHistory] = useState();
    const [outForDeleveryId, setOutForDeleveryId] = useState(0);
    const [formData, setFormData] = useState({
        outForDelevery_id: outForDeleveryId,
        salseman: "",
        distributor: "",
        noOfBundels: "",
        noOfPaketsInBundels: "",
        packetSize: "",
        product: ""
    });

    useEffect(() => {
        productListAPI();
        getPacketSizeList();
        getSalseMan();
        getDistributor();
        if (deleveryId !== undefined) {
            setOutForDeleveryId(deleveryId._id);
            getOutForDeleveryAPI(deleveryId._id);
        }
    }, []);

    //** Handller  **/
    const handleProductChange = (e) => {
        const { name, value } = e.target;
        var product = productsList.filter((packet) => packet.nameEng === value)[0];
        if (product) {
            setSelectedProduct(product);
            setFormData((prevData) => ({
                ...prevData,
                [name]: product,
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDistributorChange = (event) => {
        const { name, value } = event.target;
        if (!formData.salseman) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            alert('Salseman already selected!');
        }
    }

    const handleSalsemanChange = (event) => {
        const { name, value } = event.target;
        if (!formData.distributor) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            alert('Distributor already selected!');
        }
    }

    //** Reset Form  **/
    const resetForm = () => {
        setFormData({
            outForDelevery_id: outForDeleveryId,
            salseman: "",
            distributor: "",
            noOfBundels: "",
            noOfPaketsInBundels: "",
            packetSize: "",
            product: selectedProduct._id
        });
    }

    //** API Calls  **/
    const productListAPI = async () => {
        const response = await getProducts(authToken);
        if (response.success) {
            const products = [...response.products].sort((a, b) => a.nameEng > b.nameEng ? 1 : -1,);
            setProducts(products);
            setSelectedProduct(products[0]);
            setFormData((prevData) => ({
                ...prevData,
                product: products[0],
            }));
        }
    };

    const getPacketSizeList = async () => {
        const response = await getPacketSize(authToken);
        if (response.success) {
            const packetSize = [...response.packetSize];
            setPacketSize(packetSize);
        }
    };

    const getSalseMan = async () => {
        const response = await getSalseManListAPI(authToken);
        if (response.success) {
            const salseMans = [...response.salsmans];
            setSalseManList(salseMans);
        }
    };

    const getDistributor = async () => {
        const response = await getDistributorListAPI(authToken);
        if (response.success) {
            const distributors = [...response.distributors];
            setDistributorList(distributors);
        }
    };

    const getOutForDeleveryAPI = async (ofdeleveryId) => {
        const response = await getOutForDeleveryForID(authToken, ofdeleveryId);
        if (response.success) {
            const outForDeleveryHistoryArray = response.outForDelevery;
            setoutForDeleveryHistory(outForDeleveryHistoryArray);
        }
    };

    const handleAddInventory = async () => {
        var selectedPacketSize = packetSizeList.filter((packet) => packet.value === parseInt(formData.packetSize))[0];
        var distributorSelected = distributorList.filter((distributor) => distributor.name === formData.distributor)[0];
        var salsemanSelected = salseManList.filter((salseman) => salseman.name === formData.salseman)[0];
        const payload = {
            outForDelevery_id: outForDeleveryId,
            salsman_id: salsemanSelected ? salsemanSelected._id : "",
            distributor_id: distributorSelected ? distributorSelected._id : "",
            noOfBundels: formData.noOfBundels,
            noOfPaketsInBundels: formData.noOfPaketsInBundels,
            packetSize: selectedPacketSize._id,
            product: selectedProduct._id
        }
        const response = await postOutForDelevery(authToken, payload);
        if (response.success) {
            alert('OutForDelevery added successfully!');
            setOutForDeleveryId(response.outForDelevery._id)
            getOutForDeleveryAPI(response.outForDelevery._id);
        } else {
            resetForm();
        }
    };


    const handleDelete = async (prepairdInventoryId) => {

        const isConfirmed = window.confirm('Are you sure you want to delete this record?');

        if (isConfirmed) {
            console.log(`Deleting product with ID: ${prepairdInventoryId}`);
            const payload = {
                id: outForDeleveryId,
                preparedInventoryId: prepairdInventoryId
            }
            const response = await deleteOutForDeleveryProduct(authToken, payload);
            if (response.success) {
                alert(response.message);
                // setOutForDeleveryId(response._id);
                getOutForDeleveryAPI(response.updatedRecord._id);
            } else {
                resetForm();
            }
        }
    };

    const handleClearDistributorClick = () => {
        // setSelectedValue('');
        setFormData((prevData) => ({
            ...prevData,
            distributor: "",
        }));
    };

    const handleClearSalsemanClick = () => {
        setFormData((prevData) => ({
            ...prevData,
            salseman: "",
        }));
    };

    return (
        <div>
            <button onClick={onClose}>Back to Product List</button>
            <form>
                {selectedProduct !== undefined && selectedProduct.nameEng !== undefined ?
                    (<div>
                        <div className="product-detail">
                            <img className="product-image" src={process.env.REACT_APP_API_URL + selectedProduct.image} alt={selectedProduct.name} />
                            <h3>{selectedProduct.nameEng + "(" + selectedProduct.nameHindi + ")"}</h3>
                        </div>
                    </div>)
                    : null}
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <select style={{ width: '50%', height: '30px' }} name="productName" value={formData.productsList} onChange={handleProductChange}>
                        {productsList.map((productsList) => (<option value={productsList.nameEng}>{productsList.nameEng + " (" + productsList.nameHindi + ")"}</option>))}
                    </select>
                </div>
                <div>
                    <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                        <label>
                            Packet Size:
                            <select style={{ width: '100%', height: '30px' }} name="packetSize" value={formData.packetSize} onChange={handleChange}>
                                <option value="" selected disabled hidden>Select Size</option>
                                {packetSizeList.map((packetSize) => (<option value={packetSize.value}>{packetSize.value} {packetSize.unit.shortName}</option>))}
                            </select>
                        </label>
                    </div>
                </div>
                <br></br>
                <div>
                    <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                        <label>
                            Distributor :
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <select style={{ width: '100%', height: '30px' }} name="distributor" value={formData.distributor} onChange={handleDistributorChange}>
                                    <option value="" selected >Select Distributor</option>
                                    {distributorList.map((distributor) => (<option value={distributor.name}>{distributor.name}</option>))}
                                </select>
                                {formData.distributor && (
                                    <button className="clear-button" onClick={handleClearDistributorClick}>
                                        &#10005; {/* Unicode character for "x" (cross) */}
                                    </button>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <br></br>
                <div>
                    <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                        <label>
                            Salseman :
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <select style={{ width: '100%', height: '30px' }} name="salseman" value={formData.salseman} onChange={handleSalsemanChange}>
                                    <option value="" selected >Select Salseman</option>
                                    {salseManList.map((salseman) => (<option value={salseman.name}>{salseman.name}</option>))}
                                </select>
                                {formData.salseman && (
                                    <button className="clear-button" onClick={handleClearSalsemanClick}>
                                        &#10005; {/* Unicode character for "x" (cross) */}
                                    </button>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <br></br>
                <label>
                    No. Of Bundels:
                    <input
                        type="number"
                        name="noOfBundels"
                        value={formData.noOfBundels}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    No. Of Packets per Bundels:
                    <input
                        type="number"
                        name="noOfPaketsInBundels"
                        value={formData.noOfPaketsInBundels}
                        onChange={handleChange}
                    />
                </label>
                <br></br>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button type="button" onClick={handleAddInventory}>
                        Save
                    </button>
                </div>
            </form>
            {outForDeleveryHistory !== undefined && outForDeleveryHistory.prepairdInventory ? (<div> <div>Date : {outForDeleveryHistory.date}</div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell>Product Name</TableCell>
                                <TableCell>Bundels</TableCell>
                                <TableCell>Pakets per Bundels </TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>Balance Packets</TableCell>
                                <TableCell>Balance Bundel</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {outForDeleveryHistory.prepairdInventory.map((entry, index) => (
                                <TableRow key={index}>
                                    <TableCell>{entry.product.nameEng + "(" + entry.product.nameHindi + ")"}</TableCell>
                                    <TableCell>{entry.noOfBundels}</TableCell>
                                    <TableCell>{entry.noOfPaketsInBundels}</TableCell>
                                    <TableCell>{entry.packetSize.value + entry.packetSize.unit.shortName}</TableCell>
                                    <TableCell>{entry.balancePackets}</TableCell>
                                    <TableCell>{entry.balanceBundel}</TableCell>
                                    <TableCell> <Button variant="outlined" color="secondary" onClick={() => handleDelete(entry._id)}>
                                        Delete
                                    </Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table></TableContainer></div>)
                : null}
        </div >
    );
};

export default OutForDeleveryForm;