import React from 'react'
import { Helmet } from 'react-helmet'
import GalleryCard3 from '../../components/gallery-card3'
import './home.css'
import './about-us.css'
import { Link } from 'react-router-dom';
import WhatsAppFloatingButton from './WhatsAppFloatingButton';
import Front from '../../images/Front.jpeg';
import GaramMasala from '../../images/SpiceProducts/GaramMasala.jpeg';
import PunchPhoran from '../../images/SpiceProducts/PunchPhoran.jpeg';

import Cumin from '../../images/SpiceProducts/Cumin.jpeg';
import GreenCardomom from '../../images/SpiceProducts/GreenCardomom.jpeg';
import Clove from '../../images/SpiceProducts/Clove.jpeg';
import Rai from '../../images/SpiceProducts/Rai.jpeg';
import Cinnamon from '../../images/SpiceProducts/Cinamon.jpeg';
import Methi from '../../images/SpiceProducts/Methi.jpeg';
import Kaloungi from '../../images/SpiceProducts/Kaloungi.jpeg';
import Dhaniya from '../../images/SpiceProducts/Dhaniya.jpeg';
import BlackPepper from '../../images/SpiceProducts/BlackPepper.jpeg';
import Sounf from '../../images/SpiceProducts/Sounf.jpeg';
import Nutmeg from '../../images/SpiceProducts/Nutmeg.jpeg';
import BLACKCardomom from '../../images/SpiceProducts/BLACKCardomom.jpeg';
import Ajwain from '../../images/SpiceProducts/Ajwain.jpeg';

import Almond from '../../images/SpiceProducts/Almond.jpeg';
import Kishmish from '../../images/SpiceProducts/Kishmish.jpeg';
import Mishari from '../../images/SpiceProducts/Mishari.jpeg';

import DhaniyaPowder from '../../images/SpiceProducts/DhaniyaPowder.jpeg';
import ChilliPowder from '../../images/SpiceProducts/ChilliPowder.jpeg';
import Termeric from '../../images/SpiceProducts/Termeric.jpeg';

import Header from './header';
import Footer from './footer';
import Url from '../../urls.service'

const Home = (props) => {

  const handleDownload = () => {
    // Fetch the PDF file from the server
    fetch(Url.catelogPDF)
      .then((response) => response.blob())
      .then((blob) => {
        //  const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = Url.catelogPDF;
        link.setAttribute('download', 'MBNProductsCatalogue.pdf');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(Url.catelogPDF);
      })
      .catch((error) => {
        console.error('Error downloading PDF:', error);
      });
  };



  return (
    <div className="home-container">
      <Helmet>
        <title>MBN</title>
        <meta property="og:title" content="MBN" />
      </Helmet>
      <div className="home-Top-Background">
        <Header />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="home-hero1">
            <div className="home-container1">
              <h1 className="home-hero-heading heading1">
                Discover the Spice Collection
              </h1>
              <span className="home-hero-sub-heading">
                Spice up your life with our unique products
              </span>
              <div className="home-btn-group">
                <Link className="home-hero-button1 button" to="/contactUs">Get in touch with us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-details">
        <div className="home-details1">
          <div className="home-container2">
            <h2 className="home-details-heading heading2">
              Nishant Spices
            </h2>
            <span className="home-details-sub-heading">
              We are passionate about providing high-quality spices sourced from
              around the world. With our product listing, you can
              easily browse and purchase your favorite spices. Contact us today
              to learn more about our products and services.
            </span>
          </div>
          <img
            src={Front}
            className="home-details-image"
            alt="home details"
          />
        </div>
      </div>
      <div className="home-features"></div>
      <div className="home-pricing"></div>
      <div className="home-gallery">
        <div className="home-gallery1">
          <h1 className="home-gallery-heading heading2">Our Products</h1>
          <span className="home-gallery-sub-heading">
            Explore our collection of high-quality products
          </span>
        </div>
      </div>
      <div className="home-gallery">
        <div className="home-gallery1">
          <button className="home-hero-button1 button" onClick={handleDownload}>
            Download Catalogue
          </button>
        </div>
      </div>
      <div className="home-gallery">
        <div className="home-gallery1">
          <h1 className="home-gallery-heading heading2">Whole Spices</h1>
          <div className="home-container3">
            <GalleryCard3
              image_src={GaramMasala}
              rootClassName="rootClassName1"
              name="Whole Garam Masala"
            ></GalleryCard3>
            <GalleryCard3
              image_src={PunchPhoran}
              rootClassName="rootClassName1"
              name="PunchPhoran"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Cumin}
              rootClassName="rootClassName1"
              name="Cumin"
            ></GalleryCard3>
            <GalleryCard3
              image_src={GreenCardomom}
              rootClassName="rootClassName1"
              name="Green Cardomom"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Clove}
              rootClassName="rootClassName1"
              name="Clove"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Rai}
              rootClassName="rootClassName1"
              name="Rai"
            ></GalleryCard3>

            <GalleryCard3
              image_src={Cinnamon}
              rootClassName="rootClassName1"
              name="Cinnamon"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Methi}
              rootClassName="rootClassName1"
              name="Methi"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Kaloungi}
              rootClassName="rootClassName1"
              name="Kaloungi"
            ></GalleryCard3>

            <GalleryCard3
              image_src={Dhaniya}
              rootClassName="rootClassName1"
              name="Dhaniya"
            ></GalleryCard3>

            <GalleryCard3
              image_src={BlackPepper}
              rootClassName="rootClassName1"
              name="Black Pepper"
            ></GalleryCard3>

            <GalleryCard3
              image_src={Sounf}
              rootClassName="rootClassName1"
              name="Sounf"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Nutmeg}
              rootClassName="rootClassName1"
              name="Nutmeg"
            ></GalleryCard3>

            <GalleryCard3
              image_src={BLACKCardomom}
              rootClassName="rootClassName1"
              name="Black Cardomom"
            ></GalleryCard3>

            <GalleryCard3
              image_src={Ajwain}
              rootClassName="rootClassName1"
              name="Ajwain"
            ></GalleryCard3>
          </div>
        </div>
      </div>
      <div className="home-gallery">
        <div className="home-gallery1">
          <h1 className="home-gallery-heading heading2">Dry Fruits</h1>
          <div className="home-container3">
            <GalleryCard3
              image_src={Almond}
              rootClassName="rootClassName1"
              name="Almond"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Kishmish}
              rootClassName="rootClassName1"
              name="Kishmish"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Mishari}
              rootClassName="rootClassName1"
              name="Mishari"
            ></GalleryCard3>
          </div>
        </div>
      </div>
      <div className="home-gallery">
        <div className="home-gallery1">
          <h1 className="home-gallery-heading heading2">Powder Spices</h1>
          <div className="home-container3">
            <GalleryCard3
              image_src={DhaniyaPowder}
              rootClassName="rootClassName1"
              name="Dhaniya Powder"
            ></GalleryCard3>
            <GalleryCard3
              image_src={ChilliPowder}
              rootClassName="rootClassName1"
              name="Red Chilli Powder"
            ></GalleryCard3>
            <GalleryCard3
              image_src={Termeric}
              rootClassName="rootClassName1"
              name="Termeric"
            ></GalleryCard3>
          </div>
        </div>
      </div>
      <div className="home-faq"></div>
      <div className="home-faq"></div>
      <Footer />
      <WhatsAppFloatingButton />
    </div >
  )
}

export default Home
