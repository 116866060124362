const Url = {
   login: process.env.REACT_APP_API_URL + 'api/v1/user/login',
   productCategory: process.env.REACT_APP_API_URL + 'api/v1/productCategory',
   unit: process.env.REACT_APP_API_URL + 'api/v1/unit',
   product: process.env.REACT_APP_API_URL + 'api/v1/product',
   supplier: process.env.REACT_APP_API_URL + 'api/v1/supplier',
   rowInventory: process.env.REACT_APP_API_URL + 'api/v1/rowInventory',
   packetSize: process.env.REACT_APP_API_URL + 'api/v1/packetSize',
   prepairedProductInventory: process.env.REACT_APP_API_URL + 'api/v1/prepairedProductInventory',
   distributor: process.env.REACT_APP_API_URL + 'api/v1/distributor',
   salsman: process.env.REACT_APP_API_URL + 'api/v1/salsman',
   outForDelevery: process.env.REACT_APP_API_URL + 'api/v1/outForDelevery',
   generateKeyPair: process.env.REACT_APP_API_URL + 'api/v1/generateKeyPair',
   catelogPDF: "https://drive.google.com/file/d/1k3XJp8edgVrILuGBVBi3ALJTHoNoZr75/view?usp=sharing",

   // user: `${process.env.REACT_APP_URL}user`,
}

// const Url = {
//    login: 'http://localhost:5252/api/v1/user/login',
//    productCategory: 'http://localhost:5252/api/v1/productCategory',
//    unit: 'http://localhost:5252/api/v1/unit',
//    product: 'http://localhost:5252/api/v1/product',
//    supplier: 'http://localhost:5252/api/v1/supplier',
//    rowInventory: 'http://localhost:5252/api/v1/rowInventory',
//    packetSize: 'http://localhost:5252/api/v1/packetSize',
//    prepairedProductInventory: 'http://localhost:5252/api/v1/prepairedProductInventory',
//    distributor: 'http://localhost:5252/api/v1/distributor',
//    salsman: 'http://localhost:5252/api/v1/salsman',
//    outForDelevery: 'http://localhost:5252/api/v1/outForDelevery',
//    generateKeyPair: 'http://localhost:5252/api/v1/generateKeyPair',
//    catelogPDF: "https://drive.google.com/file/d/1k3XJp8edgVrILuGBVBi3ALJTHoNoZr75/view?usp=sharing",
//    // user: `${process.env.REACT_APP_URL}user`,
// }

export default Url;