import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../RowInventory/ProductList.css';
import { postProducts } from './productAPI';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { arrowLeft } from '@fortawesome/free-solid-svg-icons';
const AddProductForm = ({ onClose }) => {

    const [productImage, setProductImage] = useState(null);
    const [formData, setFormData] = useState({
        nameEng: '',
        nameHindi: '',
        categoryId: '',
        image: '',
        description: '',
        active: true,
    });

    const categories = useSelector((state) => state.masterData.categories);
    const token = useSelector((state) => state.auth.authToken);
    const [isRowMaterial, setIsRowMaterial] = useState(true);
    const [isPrepairedProduct, setisPrepairedProduct] = useState(true);


    useEffect(() => {
        if (categories !== null) {
            setFormData((prevData) => ({
                ...prevData,
                categoryId: categories[0],
            }));
        }
    }, []);


    const handleCheckboxChange = () => {
        setIsRowMaterial(!isRowMaterial);
    };
    const handleProductCheckboxChange = () => {
        setisPrepairedProduct(!isPrepairedProduct);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setProductImage(file);

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Add logic to save the new product data
        const formDataPost = new FormData();
        formDataPost.append('nameEng', formData.nameEng);
        formDataPost.append('nameHindi', formData.nameHindi);
        formDataPost.append('categoryId', formData.categoryId._id);
        formDataPost.append('image', productImage);
        formDataPost.append('description', formData.description);
        formDataPost.append('active', formData.active);
        formDataPost.append('isRowProduct', isRowMaterial);
        formDataPost.append('isPrepairedProduct', isPrepairedProduct);
        console.log('Product form submitted:', formDataPost);
        const responce = await postProducts(token, formDataPost);

        if (responce.success) {
            alert('Product added successfully!');
            onClose();
        }
        // 
    };

    return (
        <div>
            <h3>Add Product</h3>
            <button type="button" onClick={onClose}>
                {/* <FontAwesomeIcon icon="fa-solid fa-arrow-left" /> */}
                Back
            </button>
            <form onSubmit={handleSubmit}>
                <label>
                    English Name:
                    <input type="text" name="nameEng" value={formData.nameEng} onChange={handleChange} />
                </label>
                <label>
                    Hindi Name:
                    <input type="text" name="nameHindi" value={formData.nameHindi} onChange={handleChange} />
                </label>
                <label>
                    Category:
                    <br></br>
                    <select style={{ width: '100%', height: '35px' }} name="categoryId" value={formData.categoryId} onChange={handleChange}>
                        {categories.map((category) => (<option value={category._id} > {category.name}</option>))}
                    </select>
                </label>
                <img
                    src={formData.image}
                    className="product-image-form"
                    alt="Product Image"
                />
                <label>
                    Image URL:
                    {/* <input type="text" name="image" value={formData.image} onChange={handleChange} /> */}
                    <input type="file" accept="image/*" onChange={handleImageChange} />
                </label>
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <label style={{ width: '50%', height: '30px' }}>
                        Is a row material?
                    </label>
                    <input
                        style={{ width: '50%', height: '30px' }}
                        type="checkbox"
                        checked={isRowMaterial}
                        onChange={handleCheckboxChange}
                    />
                </div>
                <br></br>
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <label style={{ width: '50%', height: '30px' }}>
                        Is a product?
                    </label>
                    <input
                        style={{ width: '50%', height: '30px' }}
                        type="checkbox"
                        checked={isPrepairedProduct}
                        onChange={handleProductCheckboxChange}
                    />
                </div>
                <label>
                    Description:
                    <textarea name="description" value={formData.description} onChange={handleChange} />
                </label>
                <button type="submit">Save</button>


            </form>
        </div >
    );
};

export default AddProductForm;