
import Url from "../../../urls.service";

export const getProducts = async (authToken) => {
    const apiUrl = `${Url.product}?isPrepairedProduct=true`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};

export const postProducts = async (authToken, payload) => {
    const apiUrl = Url.product;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken,
        },
        body: payload,
    });
    if (!response.ok) {
        throw new Error('Product Add Failed!');
    }
    return response.json();
};

export const getPacketSize = async (authToken) => {
    const apiUrl = Url.packetSize;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};

export const postPrepairedInventory = async (authToken, payload) => {
    const apiUrl = Url.prepairedProductInventory;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
        throw new Error('Product Add Failed!');
    }
    return response.json();
};

export const getPrepairedInventory = async (authToken, payload) => {
    const apiUrl = `${Url.prepairedProductInventory}?product=${payload}`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + authToken,
        },
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};