
import Url from "../../../urls.service";

export const getOutForDelevery = async (authToken) => {
    const apiUrl = Url.outForDelevery
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};

export const getOutForDeleveryForID = async (authToken, id) => {
    const apiUrl = `${Url.outForDelevery}/getByID?outForDelevery_id=${id}`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};

export const getProducts = async (authToken) => {
    const apiUrl = `${Url.product}?isPrepairedProduct=true`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};

export const postProducts = async (authToken, payload) => {
    const apiUrl = Url.product;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken,
        },
        body: payload,
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};

export const getPacketSize = async (authToken) => {
    const apiUrl = Url.packetSize;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};

export const getSalseManListAPI = async (authToken) => {
    const apiUrl = Url.salsman;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};

export const getDistributorListAPI = async (authToken) => {
    const apiUrl = Url.distributor;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};

export const postOutForDelevery = async (authToken, payload) => {
    const apiUrl = Url.outForDelevery;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
        body: JSON.stringify(payload),
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};

export const getPrepairedInventory = async (authToken, payload) => {
    const apiUrl = `${Url.prepairedProductInventory}?product=${payload}`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + authToken,
        },
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};


export const deleteOutForDeleveryProduct = async (authToken, payload) => {
    const apiUrl = Url.outForDelevery;
    const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
        body: JSON.stringify(payload),
    });
    var responcevalue = await response.json()
    if (!response.ok) {
        alert(responcevalue.message);
    }
    return responcevalue;
};
