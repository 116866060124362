// FloatingButton.js
import React from 'react';
import './WhatsAppFloatingButton.css';
import WhatsApp from '../../images/whatsApp.png';

const WhatsAppFloatingButton = () => {
    return (
        <div>
            <a
                href="https://wa.me/9044554119"
                target="_blank"
                rel="noopener noreferrer"
                className="floating-button"
            >
                <img className="whatsApp-logo" src={WhatsApp} alt="Whats App logo" ></img>
            </a>

        </div>
    );
};

export default WhatsAppFloatingButton;
