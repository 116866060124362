import React from 'react'
import { Helmet } from 'react-helmet'
import './home.css'
import './about-us.css'
import logo2 from '../../images/LogoMBN_black.png';
import Header from './header';
import Footer from './footer';
import WhatsAppFloatingButton from './WhatsAppFloatingButton';
const AboutUs = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>MBN</title>
        <meta property="og:title" content="MBN" />
      </Helmet>
      <div className="home-Top-Background">
        <Header />
      </div>

      <div className="home-details">
        <div className="home-gallery">
          <div className="home-gallery1">
            <img className="aboutus-logo" src={logo2} alt="My Local" />
            {/* <h1 className="home-gallery-heading heading2"> MBN (Manufactured By Nature)</h1> */}
            <section>
              <h2 className="home-details-heading heading2">Our History</h2>
              <span className="home-details-sub-heading">
                MBN was founded in 2022 by a group of spice enthusiasts who shared a common dream – to bring the world's finest and most exotic spices to your kitchen. Our story is one of exploration and discovery, as we traversed the spice markets of distant lands to curate a collection that embodies the rich tapestry of global culinary traditions.
              </span>
            </section>

            <section>
              <h2 className="home-details-heading heading2">Our Mission</h2>
              <span className="home-details-sub-heading">
                At the heart of MBN is a commitment to enhancing your culinary adventures. Our mission is to provide you with access to the highest quality spices, sourced responsibly from growers around the globe. We believe that every meal is an opportunity to embark on a flavorful journey, and our mission is to empower you to do just that.
              </span>
            </section>

            <section>
              <h2 className="home-details-heading heading2">Our Values</h2>
              <ul>
                <li className="home-details-sub-heading" ><strong>Quality First:</strong> We adhere to the highest standards of quality, ensuring that every spice we offer is meticulously selected for its purity and freshness.</li>
                <li className="home-details-sub-heading" ><strong>Sustainability:</strong> We are dedicated to promoting sustainable and ethical practices in the spice industry. Our partnerships with farmers prioritize fair trade and environmentally conscious methods.</li>
                <li className="home-details-sub-heading" ><strong>Culinary Exploration:</strong> MBN is more than a spice emporium; it's a gateway to culinary exploration. We encourage you to experiment with flavors, try new recipes, and discover the art of spice blending.</li>
              </ul>
            </section>

            <section>
              <h2>Why Nishant Spices?</h2>
              <ul>
                <li className="home-details-sub-heading"><strong>Exotic Selection:</strong> Explore our extensive range of spices, from rare finds to everyday essentials. Each product tells a story and brings a touch of authenticity to your kitchen.</li>
                <li className="home-details-sub-heading"><strong>Expertise:</strong> Our team of spice enthusiasts and culinary experts is passionate about sharing their knowledge. We provide resources and insights to help you make the most of your spice journey.</li>
                <li className="home-details-sub-heading"><strong>Community:</strong> Join the MBN community, where food lovers and spice enthusiasts come together to share recipes, tips, and stories. Follow us on social media and be part of the conversation.</li>
              </ul>
            </section>

            <p className="closing-statement">
              Nishant Spices - Your Passport to Flavorful Discoveries!
            </p>
          </div>
        </div></div>
      <div className="home-faq"></div>
      <Footer />
      <WhatsAppFloatingButton />
    </div >
  )
}

export default AboutUs
