export const loginSuccess = () => {
    return {
        type: 'LOGIN_SUCCESS',
    };
};

export const loginFailure = () => {
    return {
        type: 'LOGIN_FAILURE',
    };
};

export const setAuthToken = (token) => ({
    type: 'SET_AUTH_TOKEN',
    payload: token,
});

export const clearAuthToken = () => ({
    type: 'CLEAR_AUTH_TOKEN',
});

export const setProductCategory = (category) => ({
    type: 'SET_CATEGORIES',
    payload: category,
})



export const clearPublicKey = () => ({
    type: 'CLEAR_PUBLIC_KEY',
});

export const setPublicKey = (key) => ({
    type: 'SET_PUBLIC_KEY',
    payload: key,
})