import React, { useEffect, useState } from 'react';
import './ProductList.css';
import { useSelector } from 'react-redux';
import { getSuppliers } from '../Supplier/SupplierAPI';
import { getProducts } from './productAPI';
import { getUnits } from './productAPI';
import { postRawInventory } from './productAPI';
import { getRawInventory } from './productAPI';

const ProductInventoryForm = ({ product, onClose }) => {
    const [selectedProduct, setSelectedProduct] = useState(product);
    const [suppliers, setSuppliers] = useState([]);
    const [units, setUnit] = useState([]);
    const [productsList, setProducts] = useState([]);
    const authToken = useSelector((state) => state.auth.authToken);
    const [inventoryHistory, setInventoryHistory] = useState([]);
    const [formData, setFormData] = useState({
        nameEng: selectedProduct.nameEng,
        productImage: selectedProduct.image,
        action: "Add",
        supplier: suppliers,
        quantity: 0,
        totalQty: '',
        unit: '',
        unitPrice: 0,
        cgst: 2.5,
        sgst: 2.5,
        totalAmount: 0,
        active: true,
        initaialQty: 0,
        addedQty: 0,
        withdrawalQty: 0,
        balanceQty: 0,
    });

    useEffect(() => {
        setSelectedProduct(product);
        // productListAPI();
        getSuppliersAPI();
        getUnitsList();
        getRawInventoryList();
    }, []);


    // const productListAPI = async () => {
    //     const response = await getProducts(authToken);
    //     if (response.success) {
    //         const products = [...response.products].sort((a, b) => a.nameEng > b.nameEng ? 1 : -1,);
    //         setProducts(products);
    //     }
    // };

    const getUnitsList = async () => {
        const response = await getUnits(authToken);
        if (response.success) {
            const unit = [...response.unit];
            setUnit(unit);
            setFormData((prevData) => ({
                ...prevData,
                unit: unit[0].name,
            }));
        }
    };

    const getRawInventoryList = async () => {
        const response = await getRawInventory(authToken, selectedProduct._id);
        if (response.success) {
            const rawInventorySchemaArray = [...response.rawInventorySchemaArray];
            setInventoryHistory(rawInventorySchemaArray);
        }
    };

    // const updateRawInventoryHistory = () => {
    //     setTimeout(function () {
    //         setInventoryHistory([]);
    //         resetForm();
    //         getRawInventoryList();
    //     }.bind(this), 1000)
    // }

    const getSuppliersAPI = async () => {
        const response = await getSuppliers(authToken);
        if (response.success) {
            const suppliers = [...response.suppliers].sort((a, b) => a.name > b.name ? 1 : -1,);
            setSuppliers(suppliers);
            setFormData((prevData) => ({
                ...prevData,
                supplier: suppliers[0].name,
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "productName") {
            var product = productsList.filter((product) => product.nameEng === value)[0];
            //setSelectedProduct(product);
            setSelectedProduct(product);
            setFormData((prevData) => ({
                ...prevData,
                [name]: product.nameEng,
            }));
            // updateRawInventoryHistory();
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleCalculateTotalAmount = () => {
        const { quantity, unitPrice, cgst, sgst } = formData;
        const tax = (Number(cgst) + Number(sgst));
        const price = (Number(quantity) * Number(unitPrice));
        const totalAmount = price + (price * tax / 100);
        setFormData((prevData) => ({ ...prevData, totalAmount }));
    };

    const totalAmountCalculator = (quantity, unitPrice, cgst, sgst) => {
        // const { quantity, unitPrice, cgst, sgst } = formData;
        const tax = (Number(cgst) + Number(sgst));
        const price = (Number(quantity) * Number(unitPrice));
        const totalAmount = price + (price * tax / 100);
        return totalAmount
    };

    const handleAddInventory = async () => {
        if (formData.quantity !== 0) {
            var supplier = suppliers.filter((supplier) => supplier.name === formData.supplier)[0];
            var unit = units.filter((unit) => unit.name === formData.unit)[0];
            if (formData.action === "Add") {
                const response = await postRawInventory(authToken, {
                    quantity: formData.quantity,
                    unit: unit._id,
                    unitPrice: formData.unitPrice,
                    cgst: formData.cgst,
                    sgst: formData.sgst,
                    supplier: supplier._id,
                    product: selectedProduct._id,
                    action: formData.action,
                    initaialQty: 0,
                    addedQty: formData.quantity,
                    withdrawalQty: 0,
                    balanceQty: 0,
                });
                if (response.success) {
                    alert('Inventory added successfully!');
                    getRawInventoryList();
                    resetForm();
                } else {
                    resetForm();
                }
            } else {
                const response = await postRawInventory(authToken, {
                    quantity: formData.quantity,
                    unit: unit._id,
                    unitPrice: formData.unitPrice,
                    cgst: formData.cgst,
                    sgst: formData.sgst,
                    supplier: supplier._id,
                    product: selectedProduct._id,
                    action: formData.action,
                    initaialQty: 0,
                    addedQty: 0,
                    withdrawalQty: formData.quantity,
                    balanceQty: 0,
                });
                if (response.success) {
                    alert('Inventory added successfully!');
                    getRawInventoryList();
                    resetForm();
                } else {
                    resetForm();
                }
            }
        }
    };
    const resetForm = () => {
        // setFormData((prevData) => ({
        //     ...prevData,
        //     [quantity]: 0,
        // }));

    }

    return (
        <div>
            <button onClick={onClose}>Back</button>
            <form>
                <br></br>
                <div>
                    <div className="product-detail">
                        <img className="product-image" src={process.env.REACT_APP_API_URL + selectedProduct.image} alt={selectedProduct.name} />
                        <h3>{selectedProduct.nameEng + "(" + selectedProduct.nameHindi + ")"}   -  Avilable Qty: {product.balanceQuantity}  {product.unit ? product.unit.shortName : null}</h3>
                    </div>
                </div>
                {/* <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <select style={{ width: '50%', height: '30px' }} name="productName" value={formData.productsList} onChange={handleChange}>
                        {productsList.map((productsList) => (<option value={productsList.nameEng}>{productsList.nameEng + " (" + productsList.nameHindi + ")"}</option>))}
                    </select>
                </div> */}
                {/* <br></br> */}
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <select style={{ width: '50%', height: '30px' }} name="action" value={formData.action} onChange={handleChange}>
                        <option value="Add">New Stock(नया स्टॉक जोड़ें)</option>
                        <option value="Withdraw">Withdraw(निकालना)</option>
                    </select>
                </div>
                {/* <br></br> */}
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <label> Supplier:  </label>
                </div>
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <select style={{ width: '100%' }} name="supplier" value={formData.supplier} onChange={handleChange}>
                        {suppliers.map((supplier) => (<option value={supplier.name}>{supplier.name}</option>))}
                    </select>
                </div>
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <label> Quantity: </label>
                </div>
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <input
                        type="number"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleChange}
                        style={{ display: 'flex', gap: '10px', height: '30px' }}
                    />
                </div>
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <label>Unit: </label>
                </div>
                <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
                    <select style={{ width: '100%' }} name="unit" value={formData.unit} onChange={handleChange}>
                        {units.map((unit) => (<option value={unit.name}>{unit.name}</option>))}
                    </select>
                </div>
                <br></br>
                {formData.action === "Add" ? (<div>
                    <label>
                        Unit Price:
                        <input
                            type="number"
                            name="unitPrice"
                            value={formData.unitPrice}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        cgst:
                        <input type="number" name="cgst" value={formData.cgst} onChange={handleChange} />
                    </label>
                    <label>
                        sgst:
                        <input type="number" name="sgst" value={formData.sgst} onChange={handleChange} />
                    </label>
                    <label>
                        Total Amont:
                        <input type="number" name="totalAmount" value={formData.totalAmount} onChange={handleChange} />
                    </label>
                </div>)
                    : null}
                <div style={{ display: 'flex', gap: '10px' }}>
                    {formData.action === "Add" ? (<button type="button" onClick={handleCalculateTotalAmount}>
                        Calculate Total Amount
                    </button>) : null}
                    <button type="button" onClick={handleAddInventory}>
                        Save
                    </button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Supplier</th>
                        <th>Initaial Qty</th>
                        <th>Unit</th>
                        <th>Unit Price</th>
                        <th>Added</th>
                        <th>Withdraw</th>
                        <th>Total Amount</th>
                        <th>Balance Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {inventoryHistory.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.product.nameEng + "(" + entry.product.nameHindi + ")"}</td>
                            <td>{entry.supplier.name}</td>
                            <td>{entry.initaialQty}</td>
                            <td>{entry.unit.shortName}</td>
                            <td>{entry.unitPrice}</td>
                            <td>{entry.addedQty}</td>
                            <td>{entry.withdrawalQty}</td>
                            <td>{totalAmountCalculator(entry.addedQty, entry.unitPrice, entry.cgst, entry.sgst)}</td>
                            <td>{entry.balanceQty}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProductInventoryForm;