const initialState = {
    publicKey: null,
};

const publicKeyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PUBLIC_KEY':
            return { ...state, publicKey: action.payload, };
        case 'CLEAR_PUBLIC_KEY':
            return { ...state, publicKey: null, };
        default:
            return state;
    }
};


export default publicKeyReducer;