import Url from "../../../urls.service";

export const loginUser = async (username, password) => {
    const apiUrl = Url.login;
    console.log(apiUrl);
    //  const apiUrl = "http://localhost:5252/api/v1/user/login";
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};

export const getProductCategory = async (authToken) => {
    const apiUrl = Url.productCategory;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        },
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};

export const generateKeyPair = async () => {
    const apiUrl = Url.generateKeyPair;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) {
        throw new Error('Login failed');
    }
    return response.json();
};